<template>
	<div>
		<div class="person_infor">
			<div class="login_top">
				<!-- <div class="img"> <img src="@/assets/login/flag.png"></div> -->
				<div class="login_title">{{ $t('title.helpCenter') }}</div>
			</div>
		</div>
		<!-- <div class="infor_list">
			<div class="infor_item">
				<div class="infor_item_title">{{$t('other.commonProblem')}}</div>
				<div class="infor_item_list" v-for="(item,index) in helpCenterList" :key="index"
					@click="checkDetail(item)">
					{{item.title}}
				</div>
			</div>
		</div> -->

		<div class="table_box">
			<el-table :data="helpCenterList" border style="width: 100%">
				<el-table-column label="ID" width="88" align="center" type="index" class-name="table_id">
				</el-table-column>
				<el-table-column prop="title" :label="$t('question')" width="1100" align="center">
				</el-table-column>
				<el-table-column :label="$t('operation')" align="center">
					<template slot-scope="scope">
						<el-button @click="checkDetail(scope.row.id)" class="btn">{{ $t('checkDetail') }}</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>


		<!-- 详情 -->
		<el-dialog title="" :visible.sync="dialogVisible" width="38%">
			<div slot="title">
				<div class="title_all">
					<!-- <div class="title_img"><img src="@/assets/login/flag.png"></div> -->
					<div>{{ detail.title }}</div>
				</div>
			</div>
			<div class="content">
				<div v-html="detail.body" class="body"></div>
			</div>
		</el-dialog>


	</div>
</template>

<script>
export default {
	data() {
		return {
			dialogVisible: false,
			helpCenterList: [],
			detail: '',
			tableData: [{
				date: '1',
				name: '王小虎',
				address: '上海市普陀区金沙江路 1518 弄'
			}, {
				date: '2',
				name: '王小虎',
				address: '上海市普陀区金沙江路 1517 弄'
			}, {
				date: '3',
				name: '王小虎',
				address: '上海市普陀区金沙江路 1519 弄'
			}, {
				date: '4',
				name: '王小虎',
				address: '上海市普陀区金沙江路 1516 弄'
			}],

		}
	},

	mounted() {
		this.getHelpCenter();
	},
	methods: {


		getHelpCenter() {

			let data = {
				category_id: 25,
				type: 2,
				is_recommend: 0,
				page: 1,
				pageSize: 10000,
			}
			this.$http.helpCenter(data).then(res => {
				if (res.code == 1) {
					this.helpCenterList = res.data.data;
					console.log(this.helpCenterList, '帮助中心');
				} else {
					this.$message.info(res.msg);
				}
			})
		},

		// 查看详情
		checkDetail(e) {
			console.log(e);
			this.dialogVisible = true;
			this.$http.helpCenterDetail({
				id: e
			}).then(res => {
				if (res.code == 1) {
					this.detail = res.data;
				} else {
					this.$message.info(res.msg)
				}
			})
		},
	}
}
</script>

<style scoped="" lang="less">
.person_infor {
	width: 100%;
}

.login_top {
	display: flex;
	padding: 24px 40px;
	border-bottom: 1px solid #F3F5F6;

	.img {
		display: flex;
		align-items: center;

		img {
			width: 18px;
			height: 18px;
		}
	}

	.login_title {
		margin-left: 8px;
		font-weight: bold;
		font-size: 18px;
	}
}

.table_box {
	padding: 24px 40px;
	box-sizing: border-box;

	.el-button {
		padding: 0;
		width: 88px;
		height: 32px;
		line-height: 32px;
		background: rgba(246, 247, 249);
		border: 1px solid #DDDDDD;
		border-radius: 4px;
		box-sizing: border-box;
		text-align: center;

	}
}

.infor_list {
	text-align: left;
	padding: 0 32px;
	font-size: 18px;

	.infor_item {
		padding: 32px 0 40px;
		border-bottom: 1px dashed #DDDDDD;

		&:last-child {
			border-bottom: none;
		}

		.infor_item_title {
			color: #333333;
			font-weight: bold;
			margin-bottom: 32px;
		}

		.infor_item_list {
			margin-bottom: 24px;
			color: #333333;
			max-width: 862px;

			&:hover {
				cursor: pointer;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.title_all {
	display: flex;
	align-items: center;
	font-size: 20px;
	font-weight: bold;

	.title_img {
		margin-right: 8px;

		img {
			display: flex;
			align-items: center;
			width: 24px;
			height: 24px;
		}
	}

	.content {
		font-size: 14px;


	}
}


/deep/ .el-dialog__body {
	max-height: 436px;
	overflow-y: scroll;
}

/deep/.el-dialog__header {
	border-bottom: 1px solid #DDD !important;
}

/deep/ p{
		word-break: break-word !important;
	}

.body {
	text-align: left;
}
</style>
